.gallery {
	display: flex;
	flex-wrap: wrap;
	margin-right: -2px;
	margin-left: -2px;

	a {
		width: calc(33.3333% - 4px);
		margin: 2px;
		overflow: hidden;

		cursor: zoom-in;

		@media #{$screen-min-sm} {
			width: calc(25% - 5px);
		}

		@media #{$screen-min-md} {
			width: calc(20% - 5px);
		}
	}

	img {
		display: block;
	}
}

.gallery--4-3 {
	a {
		position: relative;

		padding-top: 21.75%;

		@media #{$screen-min-sm} {
			padding-top: 15.75%;
		}

		@media #{$screen-min-md} {
			padding-top: 12.75%;
		}
	}

	img {
		position: absolute;
		top: 50%;

		transform: translateY(-50%);
	}
}

.gallery-detail-image {
	display: flex;
	align-items: center;
	max-height: calc(100vh - 10rem);

	img {
		display: block;
		width: auto;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		object-fit: contain;
	}
}

.image-loader {
	position: relative;

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;

		content: attr(data-text);
		display: block;
		padding: 0.25em 0.75em;

		background-color: rgba(255, 255, 255, 0.5);
		transform: translate(-50%, -50%);

		color: $color-text--i;
		font-weight: bold;
	}
}

.dm-overlay.gallery-overlay {
	display: flex;
	flex-direction: column;
	cursor: zoom-out;

	.dm-dialog {
		background: none;
		cursor: default;
	}

	bhdzllr-item-slider {
		margin: 0 auto;

		&:focus,
		&:active {
			outline: none;
		}
	}
}
