// Default values form JS Cube Module to correct transform
// because of fixed width of cube-scene
$degrees: 65;
$spacing: 10;
$tangent: 0.637;

.content {
	.cube-scene {
		height: 260px;
		margin: 2rem auto;

		opacity: 0.1;
		transform: scale(0.1);
		animation: cube-in 0.5s forwards;

		@media #{$screen-min-sm} {
			height: 290px;
		}
	}

	.cube {
		height: 100%; // Nicer look until JS is loaded

		color: $color-primary;

		h2 {
			margin-top: 0.75rem;

			font-size: 1.125rem;
			text-transform: uppercase;
		}

		p {
			margin-bottom: 0.75rem;

			@media #{$screen-min-md} {
				margin-bottom: 1rem;
			}
		}
	}
	 
	.cube__nav {
		position: absolute;
		top: -1.5rem;
		right: 0;
		left: 0;

		height: 1.5rem;

		&::after {
			position: absolute;
			top: calc(1.5rem - 1px);
			right: 0;

			content: '';
			display: block;
			width: calc(100% - 60px - 1.5rem);
			height: 1px;

			background-color: $color-primary;
		}
	}

	.cube__tab {
		position: absolute;

		width: 60px;
		height: calc(100% - 1px);

		background-color: $color-card;
		border-top: 1px solid $color-primary;

		&::before {
			position: absolute;
			top: 0;
			left: 100%;

			content: '';
			display: block;
			width: 1.5rem;
			height: 1.5rem;

			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><polyline fill="#{$color-card}" points="50,50 0,50 0,0 "/><polyline fill="none" stroke="#{$color-primary}" stroke-width="2" points="0,0 50,50 "/></svg>');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 100%;
			transform: translateY(-1px);
		}
	}

	.cube-nav-left,
	.cube-nav-right {
		position: absolute;
		top: 2px; // Little optical correction

		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 100%;
		padding: 0; // iOS Safari

		background: none;
		border: none;
		cursor: pointer;

		text-align: center;

		&::after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 10px;
			height: 10px;

			background-position: center center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}

	.cube-nav-left {
		left: 0;

		&::after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"><polygon fill="#{$color-primary}" points="20,0 0,10 20,20"/></svg>');
		}
	}

	.cube-nav-right {
		right: -8px;

		&::after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"><polygon fill="#{$color-primary}" points="0,0 20,10 0,20"/></svg>');
		}
	}
	
	.cube__section {
		position: absolute; // Nicer look until JS is loaded

		height: 100%; // Nicer look until JS is loaded
		padding: 0.5rem;
		box-sizing: border-box;
		// overflow: hidden;

		border-top: none;

		.no-js & {
			position: relative;

			height: auto;
		}

		> p,
		> ul,
		> dl, {
			position: relative;
		}
	}

	@media #{$screen-min-sm} {
		.cube-scene {
			width: 420px;
		}

		.cube__section {
			&:nth-child(1) {
				transform: rotateY(0deg) translateZ(calc((420px + #{$spacing}px) / 2 / #{$tangent}));
			}

			&:nth-child(2) {
				transform: rotateY(-#{$degrees}deg) translateZ(calc((420px + #{$spacing}px) / 2 / #{$tangent}));
			}

			&:nth-child(3) {
				transform: rotateY(#{$degrees}deg) translateZ(calc((420px + #{$spacing}px) / 2 / #{$tangent}));
			}
		}
	}

	@media #{$screen-min-md} {
		.cube-scene {
			width: 540px;
		}

		.cube__section {
			padding: 1rem;

			&:nth-child(1) {
				transform: rotateY(0deg) translateZ(calc((540px + #{$spacing}px) / 2 / #{$tangent}));
			}

			&:nth-child(2) {
				transform: rotateY(-#{$degrees}deg) translateZ(calc((540px + #{$spacing}px) / 2 / #{$tangent}));
			}

			&:nth-child(3) {
				transform: rotateY(#{$degrees}deg) translateZ(calc((540px + #{$spacing}px) / 2 / #{$tangent}));
			}
		}
	}

	.cube__dl {
		position: relative;
		z-index: 2;

		display: flex;
		flex-wrap: wrap;

		dt,
		dd {
			display: flex;
			align-items: center;
		}

		dt {
			flex: 0 1 6.5rem;

			font-size: 0.85rem;
			font-weight: bold;
		}

		dd {
			width: calc(100% - 6.5rem);
			margin: 0.25rem 0;
		}
	}

	.cube__tools {
		margin: 1.5rem 0 0;
		padding: 0;

		text-align: center;

		li {
			display: inline-block;
			margin: 0.15rem 0;
			padding: 0.15rem 0.35rem;

			background-color: #213936;
			cursor: default;
			transition: background-color 2.5s ease;

			&:hover {
				background-color: #006a4e;
				transition: background-color 0.2s ease;
			}

			&::before {
				content: none;
			}
		}
	}

	.cube__hologram {
		position: absolute;
		top: 50%;
		z-index: 1;

		display: flex;
		flex-direction: column;
		width: calc(100% - 0.5rem);
		height: 100%;
		max-height: 100%;
		overflow: hidden;

		opacity: 0;
		transform: translateY(-50%);
		animation-name: fade-in;
		animation-duration: 2.5s;
		animation-fill-mode: forwards;

		@supports (mask-image: url('')) {
			mask-image: url('/img/bhdzllr-hologram-mask.png');
			mask-position: 200%;
			mask-repeat: no-repeat;
			mask-size: 75% 100%;

			@media #{$screen-min-md} {
				mask-position: 112%;
				mask-size: contain;
			}
		}

		@supports (mask-image: url('')) {
			&::before {
				position: absolute;
				z-index: 2;

				content: '';
				display: block;
				width: 100%;
				height: calc(100% + 8px);

				background: linear-gradient(to bottom,
					rgba(0, 0, 0, 0) 50%,
					rgba(0, 0, 0, 0.15) 50%
				);
				background-size: 100% 8px;
				animation: scan 0.75s infinite linear;
				mix-blend-mode: multiply;
			}
		}

		@media #{$screen-min-md} {
			width: calc(100% - 1rem);
		}

		img {
			position: absolute;
			left: 50%;

			height: 100%;

			&:nth-child(2) {
				transform: translateX(5px);
				animation-name: glitch;
				animation-duration: 2.5s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-direction: alternate;
			}
		}
	}
}

@keyframes scan {
	0% {
		margin-top: 0;
	}

	100% {
		margin-top: -8px;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.no-js .content {
	.cube-scene {
		height: auto;
		padding-left: 0.625rem;
		padding-right: 0.625rem;
	}

	.cube-nav-left,
	.cube-nav-right {
		display: none;
	}

	.cube__section {
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			transform: none;
		}

		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 0 !important;
		}

		&:nth-child(1)::before,
		&:nth-child(1)::after,
		&:nth-child(2)::before,
		&:nth-child(2)::after {
			content: none;
		}

		&:nth-child(2) {
			position: relative;
		}

		&:nth-child(2) .cube__nav,
		&:nth-child(3) .cube__nav {
			display: none;
		}
	}

	.cube__hologram {
		@supports (mask-image: url('')) {
			@media #{$screen-min-md} {
				mask-position: 128%;
				mask-size: contain;
			}
		}
	}
}

@keyframes cube-in {
	0% {
		opacity: 0.1;
		transform: scale(0.1);
	}

	100% {
		opacity: 1.0;
		transform: scale(1.0);
	}
}
