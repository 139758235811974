.flick {
	animation-name: flick;
	animation-duration: 2.5s;
}

@keyframes flick {
	0% {
		background-color: darken($color-secondary, 2%);
	}

	1% {
		background-color: darken($color-primary, 22%);
	}

	100% {
		background-color: darken($color-secondary, 2%);
	}
}
