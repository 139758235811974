/**
 * ********** Media **********
 */

.media {
	overflow: hidden;
}

.media__body {
	overflow: hidden;

	p:last-child {
		margin-bottom: 0;
	}
}

.media__meta {
	float: left;

	margin-right: 30px;

	.media--flipped & {
		float: right;

		margin-right: 0px;
		margin-left: 30px;
	}

	img {
		vertical-align: top;
	}
}

.media__title {
	margin-top: 0;
}
