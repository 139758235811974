/**
 * ********** Box Alignment **********
 */

.align-left {
	float: left;
}

.align-center {
	margin: 0 auto;
}

.align-right {
	float: right;
}
