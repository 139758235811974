/**
 * ********** Fonts **********
 */

/** Web Font Local */
@font-face {
	font-family: 'Open Sans Local';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Open Sans'), local('OpenSans');
}

/** Web Font Light */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Open Sans Light'), local('OpenSans-Light'),
	     url('../fonts/OpenSans-Light.woff2') format('woff2'),
	     url('../fonts/OpenSans-Light.woff') format('woff');
}

/** Web Font Regular */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Open Sans'), local('OpenSans'), local('Open Sans Regular'), local('OpenSans-Regular'),
	     url('../fonts/OpenSans-Regular.woff2') format('woff2'),
	     url('../fonts/OpenSans-Regular.woff') format('woff');
}

/** Web Font Bold */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
	     url('../fonts/OpenSans-Bold.woff2') format('woff2'),
	     url('../fonts/OpenSans-Bold.woff') format('woff');
}
