/**
 * ********** Module **********
 */

.module {
	background-color: $color-white;

	> div {
		@include container;
		@include container--sh;
		@include container--sv;

		> :last-child {
			margin-bottom: 0;
		}
	}
}

.module--transparent {
	background-color: transparent;
}

.module--limited {
	> div {
		max-width: 800px;
	}
}

.module--decreased-padding {
	> div {
		padding: 1rem;
	}
}

.module--edged {
	> div {
		padding: 0;
	}
}
