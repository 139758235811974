/**
 * ********** Button **********
 */

@mixin button-defaults {
	display: inline-block;
	margin: 0.25em 0;
	padding: 0.5em 1em;

	background: lighten($color-black, 95%);
	border: 1px solid lighten($color-black, 75%);
	border-bottom-width: 2px;
	border-radius: 0.2em;
	cursor: pointer;

	color: $color-text;
	font-weight: normal;
	line-height: inherit;
	text-decoration: none;
}

@mixin button-disabled {
	background: lighten($color-black, 90%);
	border-color: lighten($color-black, 80%);
	cursor: not-allowed;

	color: lighten($color-text, 35%);
}

.btn,
a.btn {
	@include button-defaults;

	&:hover,
	&:active {
		background: lighten($color-black, 90%);
	}

	&:active {
		box-shadow: inset 0 1px 3px rgba($color-black, 0.15);
	}

	&:disabled {
		@include button-disabled;
	}
}

[type="submit"],
.btn--primary,
a.btn--primary {
	@include button-defaults;

	background-color: $color-primary;
	border-color: darken($color-primary, 3%);

	color: $color-black;

	&:hover,
	&:active {
		background-color: darken($color-primary, 3%);
	}

	&:disabled {
		@include button-disabled;
	}

	&.btn--transparent {
		border-color: darken($color-primary, 3%);

		color: $color-primary;

		&:hover,
		&:active {
			border-color: darken($color-primary, 10%);

			color: darken($color-primary, 10%);
		}
	}
}

.btn--secondary,
a.btn--secondary {
	background-color: $color-secondary;
	border-color: darken($color-secondary, 3%);

	color: $color-white;

	&:hover,
	&:active {
		background-color: darken($color-secondary, 3%);
	}

	&.btn--transparent {
		border-color: $color-secondary;

		color: $color-secondary;

		&:hover,
		&:active {
			border-color: darken($color-secondary, 10%);

			color: darken($color-secondary, 10%);
		}
	}
}

.btn--transparent,
a.btn--transparent {
	background: none;
	border: 1px solid $color-text;

	&:hover,
	&:active {
		background: none;
		box-shadow: none;
	}
}

.btn--strong,
a.btn--strong {
	border-width: 2px;

	font-weight: bold;
}

.btn--small,
a.btn--small {
	padding: 0.35em 0.75em;

	line-height: 1.25;
	font-size: 0.9em;
}

.btn--large,
a.btn--large {
	font-size: 1.25em;
}

.btn--xlarge,
a.btn--xlarge {
	font-size: 1.5em;
}

.btn--expanded,
a.btn--expanded {
	display: block;
	width: 100%;
}

.btn--arrow,
a.btn--arrow {
	&::after {
		content: ' \203A';
	}
}

.btn[disabled],
.btn--disabled {
	@include button-disabled;
}

button[hidden],
.btn[hidden],
.btn--hidden {
	display: none;
}
