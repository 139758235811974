.article {
	font-size: 1.15rem;

	@media #{$screen-min-sm} {
		font-size: 1.25rem;
	}
}

.article--card {
	width: 255px;
	max-width: 255px;
	padding: 0.75rem;
	padding-bottom: 0;

	@media #{$screen-min-md} {
		width: 300px;
		max-width: 300px;
	}

	@media #{$screen-min-lg} {
		width: calc(33.33% - 0.5rem);
		max-width: calc(33.33% - 0.5rem);
	}

	a {
		z-index: 3;

		font-size: 1rem;

		.article__link-text {
			&::before,
			&::after {
				display: inline-block;
				vertical-align: middle;

				transform: translateY(-1px);

				font-size: 1.5rem;
			}

			&::before {
				content: '[';
				margin-right: 12px;
			}

			&::after {
				content: ']';
				margin-left: 12px;
			}
		}

		&::after {
			z-index: 3;
		}
	}
}

.article--list-item {
	margin-bottom: 4rem;
}

.article__header {
	position: relative;
}

.article__title {
	position: relative;
	z-index: 2;

	.article--card &,
	.article--list-item & {
		margin-top: 1.5rem;

		font-size: 1.45rem;
		color: $color-primary;
	}

	.article--list-item & {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	@media #{$screen-min-sm} {
		font-size: 2.5rem;

		.article--card &,
		.article--list-item & {
			font-size: 1.65rem;
		}
	}

	@media #{$screen-min-md} {
		padding-top: 4rem;

		font-size: 3rem;

		.article--card &,
		.article--list-item & {
			padding-top: 0;
		}
	}

	a {
		text-decoration: none;
	}
}

.article__tagline {
	position: relative;
	z-index: 2;

	color: lighten($color-text, 15%);
	font-size: 1.1rem;

	.article--card &,
	.article--list-item & {
		margin-bottom: 0;

		color: $color-white;
		font-size: 1rem;
	}

	@media #{$screen-min-sm} {
		font-size: 1.35rem;

		.article--list-item & {
			font-size: 1.15rem;
		}
	}

	@media #{$screen-min-md} {
		font-size: 1.5rem;

		.article--list-item & {
			font-size: 1.25rem;
		}
	}
}

.article__image {
	@extend .extended;
	@extend .extended--max-md;

	position: relative;
	z-index: 1;

	min-height: 60px;
	max-height: 480px;
	margin-top: -40px;
	margin-bottom: -20px;
	overflow: hidden;

	.article--card & {
		height: 150px;
		margin-top: -30px;
		margin-bottom: -40px;
		margin-right: -0.75rem;
		margin-left: -0.75rem;

		@media #{$screen-min-md} {
			margin-right: -1.25rem;
			margin-left: -1.25rem;
		}
	}

	@media #{$screen-min-sm} {
		min-height: 100px;
		margin-top: -60px;
		margin-bottom: -40px;
	}

	@media #{$screen-min-md} {
		min-height: 180px;
		margin-top: -100px;
		margin-bottom: -60px;
	}

	&::before {
		position: absolute;
		right: 0;
		left: 0;
		z-index: 2;

		content: '';
		display: block;
		width: 100%;
		height: 100%;

		background: linear-gradient(180deg, $color-background 0%, rgba($color-background, 0) 15%),
		            linear-gradient(0deg, $color-background 0%, rgba($color-background, 0) 15%),
		            linear-gradient(270deg, $color-background 0%, rgba($color-background, 0) 15%),
		            linear-gradient(90deg, $color-background 0%, rgba($color-background, 0) 15%),
		            radial-gradient(closest-side, rgba(0, 101, 78, 0.6) 0%, $color-background 130%);

		.article--card & {
			background: linear-gradient(180deg, $color-card--full 0%, rgba($color-card--full, 0) 15%),
			            linear-gradient(0deg, $color-card--full 0%, rgba($color-card--full, 0) 15%),
			            linear-gradient(270deg, $color-card--full 0%, rgba($color-card--full, 0) 15%),
			            linear-gradient(90deg, $color-card--full 0%, rgba($color-card--full, 0) 15%),
			            radial-gradient(closest-side, rgba(0, 101, 78, 0.6) 0%, $color-card--full 130%);
		}
	}

	img {
		display: block;
		width: 100%;
		max-width: none;

		transform: rotate(-3deg);

		// .article--card & {
		// 	position: absolute;
		// 	top: 50%;

		// 	transform: rotate(-3deg) translateY(-50%);
		// }
	}
}

.article__datetime,
.article__categories {
	position: relative;
	z-index: 2;

	color: $color-small;

	@media #{$screen-min-md} {
		position: absolute;
		top: 0;

		.article--card &,
		.article--list-item & {
			position: relative;
			top: 0;
		}
	}
}

.article__datetime {
	margin-bottom: 0.375em;

	font-size: 0.750rem;

	.article--card & {
		margin-bottom: 0.15em;
	}

	.article--list-item & {
		display: inline-block;
		margin-top: -0.75rem;
	}

	&::before {
		content: '//';
		margin-right: 0.25rem;
	}

	@media #{$screen-min-md} {
		font-size: 0.875rem;
	}
}

.article__pinned {
	&::before {
		content: '/';
		margin-right: 0.25rem;
	}
}

.article__categories {
	font-size: 0.875rem;

	.article--list-item & {
		display: inline-block;
		margin: 0;
	}

	span::before {
		content: '/';
		margin-right: 0.25rem;
	}

	span:first-child::before {
		content: '//';
	}

	@media #{$screen-min-md} {
		top: 1.5rem;

		font-size: 0.95rem;
	}
}

.article__meta {
	.article--list-item & {
		margin-top: -0.5rem;
		margin-bottom: 0.5rem;

		.article__categories {
			span:first-child::before {
				content: '/';
			}
		}
	}
}

.article__image ~ .article__action {
	@media #{$screen-min-md} {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		z-index: 1;

		transform: translateX(-50%);
	}
}

.article__content {
	position: relative;
	z-index: 2;

	padding-top: 1rem;

	@media #{$screen-min-md} {
		margin-top: 3rem;
	}
}

.article__footer {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.article__content,
.article__footer {
	.article--blog & {
		@extend .extended;
		@extend .extended--max-md;
		@extend .extended--space;

		background-color: $color-article;
		color: $color-text--i;

		@media #{$screen-min-lg} {
			padding: 4.5rem 7rem;
		}

		:focus {
			outline-color: $color-text--i;
		}

		::selection {
			background: lighten($color-secondary, 54%);
		}

		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4 {
			color: $color-text--i;
		}

		a {
			color: currentColor;
		}
	}
}

.article--blog {
	.article__content {
		padding-bottom: 1.5rem;
	}

	.article__footer {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}

.article__tags {
	font-size: 0.750rem;

	@media #{$screen-min-md} {
		font-size: 0.875rem;
	}
}

.article__tag {
	color: $color-text--darker;

	.article--blog & {
		color: lighten($color-text--i, 10%);
	}

	&::before {
		content: '#';
		display: inline;
		margin-right: 1px;
		margin-left: 2px;

		color: darken($color-text--darker, 25%);

		.article--blog & {
			color: lighten($color-text--i, 40%);
		}
	}

	&:first-child::before {
		margin-left: 0;
	}
}

p code {
	display: inline-block;
	padding: 0.35rem 0.25rem 0.15rem;

	background-color: $color-gray-2;

	font-family: $font-mono;
	line-height: 1;
}
