/**
 * ********** Clear **********
 */

.clear-left {
	clear: left;
}

.clear-right {
	clear: right;
}

.clear-both {
	clear: both;
}

.clearfix::before,
.clearfix::after {
	content: ' ';
	display: table;
}

.clearfix::after {
	clear: both;
}
