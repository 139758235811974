/**
 * ********** Stack **********
 */

.stack > * {
	margin: 0 0 1em;
}

.stack > :last-child {
	margin: 0;
}
