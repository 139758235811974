/**
 * ********** Link **********
 */

a,
a:link,
a:visited {
	color: $color-primary;
	text-decoration: underline;
}

a:focus,
a:hover,
a:active {
	color: lighten($color-primary, 5%);
	text-decoration: underline;
}
