@keyframes glitch {
	0% {
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}

	5% {
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}

	10% {
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}

	15% {
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}

	20% {
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}

	25% {
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}

	30% {
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}

	35% {
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}

	40% {
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}

	45% {
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}

	50% {
		clip-path: polygon(0 90%, 100% 90%, 100% 80%, 0 80%);
	}

	55% {
		clip-path: polygon(0 0, 0 0);
	}

	100% {
		clip-path: polygon(0 0, 0 0);
	}
}
