/**
 * ********** Spacing **********
 */

@for $i from 0 through 3 {
	.m-#{$i} {
		@if $i > 0 {
			margin: #{$i}em;
		} @else {
			margin: 0;
		}
	}

	.mt-#{$i} {
		@if $i > 0 {
			margin-top: #{$i}em;
		} @else {
			margin-top: 0;
		}
	}

	.mr-#{$i} {
		@if $i > 0 {
			margin-right: #{$i}em;
		} @else {
			margin-right: 0;
		}
	}

	.mb-#{$i} {
		@if $i > 0 {
			margin-bottom: #{$i}em;
		} @else {
			margin-bottom: 0;
		}
	}

	.ml-#{$i} {
		@if $i > 0 {
			margin-left: #{$i}em;
		} @else {
			margin-left: 0;
		}
	}
}

@for $i from 0 through 3 {
	.p-#{$i} {
		@if $i > 0 {
			padding: #{$i}em;
		} @else {
			padding: 0;
		}
	}

	.pt-#{$i} {
		@if $i > 0 {
			padding-top: #{$i}em;
		} @else {
			padding-top: 0;
		}
	}

	.pr-#{$i} {
		@if $i > 0 {
			padding-right: #{$i}em;
		} @else {
			padding-right: 0;
		}
	}

	.pb-#{$i} {
		@if $i > 0 {
			padding-bottom: #{$i}em;
		} @else {
			padding-bottom: 0;
		}
	}

	.pl-#{$i} {
		@if $i > 0 {
			padding-left: #{$i}em;
		} @else {
			padding-left: 0;
		}
	}
}
