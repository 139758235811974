.hand-wave {
	display: inline-block;

	transform: rotate(0deg);
	animation-name: hand-wave;
	animation-duration: 3s;
}

@keyframes hand-wave {
	0% {
		transform: rotate(0deg);
	}

	12.5% {
		transform: rotate(-15deg);
	}

	25% {
		transform: rotate(15deg);
	}

	37.5% {
		transform: rotate(-15deg);
	}

	50% {
		transform: rotate(15deg);
	}

	62.5% {
		transform: rotate(-15deg);
	}

	75% {
		transform: rotate(15deg);
	}

	87.5% {
		transform: rotate(-15deg);
	}

	100% {
		transform: rotate(0deg);
	}
}