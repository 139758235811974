/**
 * ********** Body **********
 */

html,
body {
	margin: 0;
	padding: 0;

	background-color: $color-background;

	color: $color-text;
	font-family: $font-stack;
	font-size: 1em;
	line-height: 1.625;
}
