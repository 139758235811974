.slider {
	@include scrollbar-style-horizontal;

	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	box-sizing: border-box;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;

	&:focus,
	&:active {
		outline: none;
	}

	&::after {
		content: ' ';
		white-space: pre;
		display: block;

		@media #{$screen-min-lg} {
			content: none;
		}
	}

	> * {
		flex: 1 0 auto;
		margin-right: 0.75rem;
	}

	> *:last-child {
		@media #{$screen-min-lg} {
			margin-right: 0;
		}
	}
}
