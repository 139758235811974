.hljs {
	@extend .extended;
	@extend .extended--space;

	display: block;
	overflow-x: auto;

	background-color: #233c39;

	font-size: inherit;

	@media #{$screen-min-sm} {
		font-size: 0.875rem;
	}

	@media #{$screen-min-lg} {
		padding-right: 7rem;
		padding-left: 7rem;
	}

	mark {
		// position: relative;

		// display: block;

		background: none;
		background-color: rgba(255, 255, 255, 0.15);

		color: inherit;

		// &::before {
		// 	position: absolute;

		// 	content: '';
		// 	display: block;
		// 	height: 100%;
		// 	width: 3px;
		// 	background-color: red;
		// }
	}
}

.hljs,
.hljs-tag,
.hljs-subst {
	color: #f8f8f8;
}

.hljs-strong,
.hljs-emphasis {
	color: #a8a8a8;
}

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal,
.hljs-link {
	color: #00ccbb;
}

.hljs-code,
.hljs-title,
.hljs-section,
.hljs-selector-class {
	color: #00ccaa;
}

.hljs-strong {
	font-weight: bold;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-name,
.hljs-attr {
	color: #00ddaa;
}

.hljs-symbol,
.hljs-attribute {
	color: #00eeaa;
}

.hljs-params,
.hljs-class .hljs-title {
	color: #f8f8f8;
}

.hljs-string,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-variable {
	color: #a0d9d4;
}

.hljs-comment,
.hljs-deletion,
.hljs-meta {
	color: #559988;
}
