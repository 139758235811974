/**
 * ********** Stretched Link **********
 */

.stretched-link-container {
	position: relative;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;

	content: '';
}

.stretched-link--screen-reader-text {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;

	&::after {
		content: none;
	}
}
