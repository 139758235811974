/**
 * ********** Image **********
 */

img {
	max-width: 100%;
	height: auto;
}

.js-lazy-image {
	.no-js & {
		display: none;
	}
}
