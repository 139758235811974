/**
 * ********** Visibility **********
 */

.visible {
	visibility: visible;
}

.invisible {
	visibility: hidden;
}
