/**
 * ********** Variables **********
 */

$color-white: #ffffff;
$color-black: #000000;

$color-primary:    #006fe6;
$color-secondary:  #507599;
$color-background: #ffffff;

$color-info:     #2a7ab0;
$color-warning:  #d43900;
$color-positive: #1e824c;
$color-negative: #dc2a2a;

$color-text:    #333333;
$color-text--i: #ffffff;
$color-text--lighter: #595959;
$color-text--darker: #121212;
$color-mark: #ffff00;
$color-selection: #b3d4fc;

$font-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$width-site: 1280px;
$grid-columns: 5;
$grid-gutter: 0.5em;
$row-columns: 12;
$row-gutter: 1em;

$break-xs:  320px;
$break-sm:  480px;
$break-md:  768px;
$break-lg:  960px;
$break-xl: 1200px;

$screen-min-xs: 'only screen and (min-width: #{$break-xs})';
$screen-min-sm: 'only screen and (min-width: #{$break-sm})';
$screen-min-md: 'only screen and (min-width: #{$break-md})';
$screen-min-lg: 'only screen and (min-width: #{$break-lg})';
$screen-min-xl: 'only screen and (min-width: #{$break-xl})';
